<template>
  <div class="vx-row">
    <div class="vx-col w-full  mb-base">
      <vx-card :title="title">
        <form-create :id="$route.params.id" @on-success="$router.back()"></form-create>
      </vx-card>
    </div>
  </div>
</template>

<script>
import FormCreate from './sections/Form.vue'
export default {
  components:{
    FormCreate
  },
  data() {
      return {
          title : null
      }
  },
  mounted(){
    this.is_driver_contact = this.$route.name == 'driver_contacts-create'
    if(this.is_driver_contact) {
        this.title = 'Driver'
    } else {
        this.title = 'Contact'
    }

    if(this.$route.params.id) {
        this.title = 'Edit ' + this.title
    } else {
        this.title = 'Add New ' + this.title
    }
  }
}
</script>

<style>

</style>